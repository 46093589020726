<template>
  <!-- Remove the container if you want to extend the Footer to full width. -->
  <div class="pt-5 fixed-bottom">
    <footer class="text-center text-lg-start" style="background-color: #6195f7">
      <div class="container d-flex justify-content-center py-5">
        <a href="https://www.facebook.com/grendelsistemas" target="_blank"
          ><button
            type="button"
            class="btn btn-primary"
            style="background-color: #fafafa"
          >
            <i class="fab fa-facebook-f"></i></button
        ></a>
      </div>

      <!-- Copyright -->
      <div
        class="text-center text-white p-3"
        style="background-color: rgba(0, 0, 0, 0.2)"
      >
        © {{ year }} gerarcpfcnpj.com.br - Uma ferramenta comumente utilizada
        para criar e gerenciar dados fictícios para testes.
      </div>
      <!-- Copyright -->
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    msg: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
<!-- <div>
          
        </div> -->
