<template>
  <div class="home">
    <MainComponent />
    <FooterComponent />
  </div>
</template>

<script lang="ts">
import FooterComponent from "@/components/FooterComponent.vue"; // @ is an alias to /src
import MainComponent from "@/components/MainComponent.vue"; // @ is an alias to /src
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {
    MainComponent,
    FooterComponent,
  },
});
</script>
