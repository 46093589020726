<template>
  <div class="container pt-5 col-md">
    <div class="text-center">
      <h2>Gerador de CPF/CNPJ <i class="fa fa-check"></i></h2>
    </div>

    <div class="alert alert-success" role="alert" v-if="copy">
      Copiado com sucesso!
    </div>

    <div class="alert alert-danger" role="alert" v-if="error">
      Selecio uma opção!
    </div>
    <div class="container pt-2">
      <div class="card">
        <div class="card-header text-center">
          <h4>Selecione o tipo de documento</h4>
        </div>
        <div class="card-body">
          <select
            class="form-select form-select-lg mb-3"
            aria-label=".form-select"
            v-model="type"
          >
            <option value="0">-</option>
            <option value="1">CPF</option>
            <option value="2">CNPJ</option>
          </select>
          <div class="form-check form-check-inline text-center">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              v-model="format"
              @change="formats(true)"
            />
            <label class="form-check-label" for="inlineCheckbox1"
              >Com formatação</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox2"
              v-model="not_format"
              @change="formats(false)"
            />
            <label class="form-check-label" for="inlineCheckbox2"
              >Sem formatação</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center pt-2" v-if="gerado === ''">
      <div class="form-group">
        <button class="btn btn-primary form-control" @click="gerar">
          GERAR
        </button>
      </div>
    </div>

    <div class="container text-center pt-2" v-if="gerado !== ''">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          aria-describedby="basic-addon2"
          v-model="gerado"
          id="textoInput"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="copiarTexto"
          >
            <i class="fa fa-copy"></i>
          </button>
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-primary form-control" @click="gerar">
          GERAR OUTRO
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainComponent",
  data() {
    return {
      type: "0",
      gerado: "",
      error: false,
      format: true,
      not_format: false,
      copy: false,
    };
  },
  methods: {
    gerar() {
      console.log(this.format);
      if (this.type === "1") {
        this.gerado = this.gerarCPF();
      } else if (this.type === "2") {
        this.gerado = this.gerarCNPJ();
      } else {
        this.isError();
      }
    },

    formats(isChecked: boolean) {
      if (isChecked) {
        this.format = true;
        this.not_format = false;
      } else {
        this.format = false;
        this.not_format = true;
      }
    },
    isError() {
      this.error = true;
      this.gerado = "";

      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    gerarCNPJ(): string {
      const n = 9;
      let n1 = this.getRandomInt(0, n);
      let n2 = this.getRandomInt(0, n);
      let n3 = this.getRandomInt(0, n);
      let n4 = this.getRandomInt(0, n);
      let n5 = this.getRandomInt(0, n);
      let n6 = this.getRandomInt(0, n);
      let n7 = this.getRandomInt(0, n);
      let n8 = this.getRandomInt(0, n);
      let n9 = 0; // Inicialmente setado para zero
      let n10 = 0; // Inicialmente setado para zero
      let n11 = 0; // Inicialmente setado para zero
      let n12 = 1; // Inicialmente setado para um

      let d1 =
        n12 * 2 +
        n11 * 3 +
        n10 * 4 +
        n9 * 5 +
        n8 * 6 +
        n7 * 7 +
        n6 * 8 +
        n5 * 9 +
        n4 * 2 +
        n3 * 3 +
        n2 * 4 +
        n1 * 5;
      d1 = 11 - (d1 % 11);
      if (d1 >= 10) d1 = 0;

      let d2 =
        d1 * 2 +
        n12 * 3 +
        n11 * 4 +
        n10 * 5 +
        n9 * 6 +
        n8 * 7 +
        n7 * 8 +
        n6 * 9 +
        n5 * 2 +
        n4 * 3 +
        n3 * 4 +
        n2 * 5 +
        n1 * 6;
      d2 = 11 - (d2 % 11);
      if (d2 >= 10) d2 = 0;

      if (this.format)
        return (
          "" +
          n1 +
          n2 +
          "." +
          n3 +
          n4 +
          n5 +
          "." +
          n6 +
          n7 +
          n8 +
          "/" +
          n9 +
          n10 +
          n11 +
          n12 +
          "-" +
          d1 +
          d2
        );
      else
        return (
          "" +
          n1 +
          n2 +
          n3 +
          n4 +
          n5 +
          n6 +
          n7 +
          n8 +
          n9 +
          n10 +
          n11 +
          n12 +
          d1 +
          d2
        );
    },

    gerarCPF(): string {
      const n = 9;
      let n1 = this.getRandomInt(0, n);
      let n2 = this.getRandomInt(0, n);
      let n3 = this.getRandomInt(0, n);
      let n4 = this.getRandomInt(0, n);
      let n5 = this.getRandomInt(0, n);
      let n6 = this.getRandomInt(0, n);
      let n7 = this.getRandomInt(0, n);
      let n8 = this.getRandomInt(0, n);
      let n9 = this.getRandomInt(0, n);
      let d1 =
        n9 * 2 +
        n8 * 3 +
        n7 * 4 +
        n6 * 5 +
        n5 * 6 +
        n4 * 7 +
        n3 * 8 +
        n2 * 9 +
        n1 * 10;
      d1 = 11 - (d1 % 11);
      if (d1 >= 10) d1 = 0;

      let d2 =
        d1 * 2 +
        n9 * 3 +
        n8 * 4 +
        n7 * 5 +
        n6 * 6 +
        n5 * 7 +
        n4 * 8 +
        n3 * 9 +
        n2 * 10 +
        n1 * 11;
      d2 = 11 - (d2 % 11);
      if (d2 >= 10) d2 = 0;

      if (this.format)
        return (
          "" +
          n1 +
          n2 +
          n3 +
          "." +
          n4 +
          n5 +
          n6 +
          "." +
          n7 +
          n8 +
          n9 +
          "-" +
          d1 +
          d2
        );
      else return "" + n1 + n2 + n3 + n4 + n5 + n6 + n7 + n8 + n9 + d1 + d2;
    },

    copiarTexto() {
      // Selecionar o conteúdo do input
      let texto = document.getElementById("textoInput") as HTMLInputElement;
      if (texto) texto.select();
      // Copiar o texto selecionado para a área de transferência
      document.execCommand("copy");
      // Alerta para informar que o texto foi copiado
      this.copy = true;
      setTimeout(() => {
        this.copy = false;
      }, 3000);
    },

    getRandomInt(min: number, max: number): number {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min; // O máximo é inclusivo e o mínimo é inclusivo
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
