<template>
  <div class="home">
    <main>
      <section id="content">
        <h1>Termos de Serviço</h1>
        <p>
          Os termos de serviço estabelecem as regras para o acesso e utilização
          do Gerar CPF CNPJ, incluindo qualquer conteúdo, funcionalidade e
          serviços oferecidos pelo Site, seja como convidado ou usuário
          registrado.
        </p>
        <div id="ezoic-pub-ad-placeholder-102"></div>
        <br />
        <p>
          Certifique-se de ler os termos de serviço com atenção antes de
          utilizar o Site. Esta página explica os termos de uso do Gerar CPF
          CNPJ, o usuário ao utilizar o Site fica condicionado à aceitação dos
          termos de uso.
        </p>
        <p>
          Gerar CPF CNPJ de URL é um serviço que gera dados não reais, para
          testes de desenvolvimento em geral.
        </p>

        <h2>Isenção de Responsabilidade</h2>
        <p>
          O serviço gratuito do Gerar CPF CNPJ tem algumas limitações, portanto
          não temos como garantir que o Site ou serviços sejam ininterruptos,
          seguros ou livres de erros. Nenhum dos funcionários ou proprietários
          será responsável por quaisquer erros ou omissões neste Site ou por
          qualquer dano que você possa sofrer.
        </p>
        <p>
          O Gerar CPF CNPJ não é responsável por quaisquer danos diretos,
          indiretos, incidentais, especiais ou consequenciais, perda de lucros
          ou danos resultantes de qualquer perda de dados ou interrupção de
          negócios decorrentes do uso ou incapacidade de usar este Site ou dos
          serviços ou conteúdo fornecidos a partir deste Site.
        </p>
        <p>
          Como recebemos um grande número de emails dos usuários, ficamos
          impossibilitados de responder a todas as mensagens.
        </p>
        <h2>Responsabilidade do Usuário</h2>
        <p>
          Ao utilizar este Site, você assume a responsabilidade pessoal pelos
          resultados das suas ações. Você concorda em assumir total
          responsabilidade por quaisquer danos ou lesões que você sofra como
          resultado do uso ou uso do serviço disponível neste Site ou outros
          recursos disponíveis a partir deste Site. Você concorda em usar seu
          julgamento e conduzir a devida diligência antes de tomar qualquer ação
          ou implementar qualquer plano ou política sugerida ou recomendada
          neste Site.
        </p>
        <h2>Erros e Omissões</h2>
        <p>
          Este Site oferece um serviço gratuito, mas não é garantido ser
          preciso, completo e atualizado. Embora tenhamos tomado medidas
          adequadas para assegurar a precisão das informações, não podemos
          afirmar que o Site está livre de erros. Ao usar o Site, você concorda
          que as informações podem ser imprecisas e é responsável por verificar
          qualquer informação obtida antes de tomar uma ação.
        </p>
        <h2>Sem Garantias</h2>
        <p>
          Você concorda que o Site não oferece garantia sobre os resultados de
          quaisquer ações, sejam elas recomendadas no Site ou não. O Gerar CPF
          CNPJ fornece recursos para ajudar os usuários a alcançar sucesso em
          negócios e outras áreas.
        </p>
        <p>
          Reconhece-se também que resultados anteriores não são garantia de
          resultados futuros. Portanto, resultados alcançados por terceiros
          aplicando os serviços deste Site não são uma garantia de que você ou
          qualquer outra pessoa ou entidade irão obter resultados similares.
        </p>
        <p>
          Este serviço não oferece garantias sobre o desempenho ou
          funcionamento. Além disso, o Gerar CPF CNPJ não faz representações ou
          garantias, expressas ou implícitas, quanto à informação, conteúdo ou
          serviços disponibilizados neste ou através deste Site. Até o limite
          permitido pela lei, o Gerar CPF CNPJ isenta-se de todas as garantias,
          expressas ou implícitas, incluindo garantias implícitas de
          comercialização e adequação para um propósito específico.
        </p>
        <h2>Limitação de Responsabilidade</h2>
        <p>
          Você concorda em absolver o Site de toda e qualquer responsabilidade
          ou perda que você ou qualquer pessoa ou entidade associada a você
          possa sofrer ou incorrer como resultado do uso das informações
          contidas neste Site e/ou dos recursos que você pode usar neste Site.
          Você concorda que o Site não será responsável perante você por
          qualquer tipo de dano incluindo perdas ou danos diretos, indiretos,
          especiais, incidentais, equitativos ou consequenciais pelo uso deste
          Site.
        </p>
        <p>
          As informações, software, produtos e serviços incluídos ou disponíveis
          através do Site podem incluir imprecisões ou erros tipográficos. Os
          desenvolvedores podem fazer melhorias e/ou alterações no Site a
          qualquer momento. Alterações são periodicamente adicionadas às
          informações contidas nessa página.
        </p>
        <p>
          O Site e/ou seus desenvolvedores não fazem representações sobre a
          adequação, confiabilidade, disponibilidade, atualidade e precisão das
          informações, software, produtos e serviços contidos no Site para
          qualquer finalidade. Na extensão máxima permitida pela lei aplicável,
          todas essas informações, software, produtos e serviços são fornecidos
          "no estado em que se encontram", sem garantia ou condição de qualquer
          tipo. O Site e/ou seus desenvolvedores se isentam de todas as
          garantias e condições com relação a essas informações, software,
          produtos e serviços, incluindo todas as garantias implícitas ou
          condições de comercialização, adequação a uma finalidade específica,
          título e não violação.
        </p>
        <p>
          Os desenvolvedores do Site não serão responsabilizados por quaisquer
          danos, incluindo, entre outros, perda de uso, dados ou lucros,
          resultantes do uso ou desempenho do serviço, atraso ou incapacidade de
          usar o Site ou serviços relacionados, a prestação ou falha na
          prestação de serviços, ou qualquer informação, software, produtos e
          serviços obtidos através do Site. Esta limitação aplica-se
          independentemente da causa dos danos, seja com base em contrato, ato
          ilícito, negligência, responsabilidade objetiva ou de outra forma. No
          entanto, esta limitação pode não se aplicar a você se sua jurisdição
          não permitir a exclusão ou limitação de responsabilidade por danos
          incidentais ou consequenciais.
        </p>
        <div id="ezoic-pub-ad-placeholder-108"></div>
        <br />
        <h2>Atualizações dos Termos</h2>
        <p>
          O Gerar CPF CNPJ reserva-se o direito de atualizar ou alterar estes
          termos de uso a qualquer momento, a versão mais atual sempre estará
          disponível nesta página.
        </p>
      </section>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "HomeView",
  components: {
    FooterComponent,
  },
};
</script>
